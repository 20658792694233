import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Collapse } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "../Common/withRouter"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { testToken } from "../../helpers/api_requests"

const Navbar = props => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  useEffect(() => {
    if (!user) {
      const savedUser = JSON.parse(sessionStorage.getItem("authUser"))
      if (savedUser) {
        setUser(savedUser)
      }
    }
  }, [user])

  const getTokenFromStorage = () => {
    try {
      if (window) {
        return JSON.parse(sessionStorage.getItem("token") || "{}")
      }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const doTestToken = async () => {
    try {
      const resp = await testToken({ token: getTokenFromStorage() })
      if (!resp?.success) {
        sessionStorage.setItem("authUser", null)
        sessionStorage.setItem("token", null)
        navigate("/login")
      } else if (window) {
        const savedUser = JSON.parse(sessionStorage.getItem("authUser") || "{}")
        if (savedUser) {
          setUser(savedUser)
        }
      }
    } catch (error) {
      sessionStorage.setItem("authUser", null)
      sessionStorage.setItem("token", null)
      console.log(error?.response?.data?.message)
      navigate("/login")
    }
  }

  useEffect(() => {
    doTestToken()
    // eslint-disable-next-line
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="topnav">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/book-appointment">
                    <i className="guidance-calendar me-2"></i>{" "}
                    {props.t("Appointments")}
                  </Link>
                </li>
                {user && user.type === "admin" && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      <i className="guidance-calendar me-2"></i>{" "}
                      {props.t("Booked")}
                    </Link>
                  </li>
                )}
                {user && user.type === "admin" && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/config">
                      <i className="guidance-calendar me-2"></i>{" "}
                      {props.t("Config")}
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)

import { formatLocation } from "../common/functions"
import { defaultConfig, post, put, patch, get, del } from "./api_helper"
import * as url from "./url_helper"

export const sendOtpEmail = data =>
  post(url.VERIFY_USER_EMAIL, data, defaultConfig)

export const sendOtpSms = data => post(url.VERIFY_USER_SMS, data, defaultConfig)

export const verifyOtp = data => patch(url.VERIFY_OTP, data, defaultConfig)

export const testToken = async data => {
  return get(url.TEST_TOKEN, {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      authorization: "Bearer " + data.token,
    },
  })
}

export const getRegisteredUsers = query =>
  put(url.GET_USERS_REGISTERED, query, defaultConfig)

export const getUnRegisteredUsers = query =>
  put(url.GET_USERS_UNREGISTERED, query, defaultConfig)

export const createUser = query => post(url.CREATE_USER, query, defaultConfig)

export const fetchAppointments = data =>
  post(
    `${url.FETCH_APPOINTMENT}/${formatLocation(data.location)}?status=${
      data.status ? (data.status.startsWith("Pending") ? 1 : 0) : ""
    }&page=${data.page}&limit=${data.limit}`,
    data,
    {
      ...defaultConfig,
      headers: {
        ...defaultConfig.headers,
        authorization: "Bearer " + data.token,
      },
    }
  )

export const fetchBlockers = data =>
  get(
    `${url.FETCH_BLOCKERS}?type=${data.type || ""}&page=${data.page}&limit=${
      data.limit
    }`,
    {
      ...defaultConfig,
      headers: {
        ...defaultConfig.headers,
        authorization: "Bearer " + data.token,
      },
    }
  )

export const createBlocker = data =>
  post(`${url.FETCH_BLOCKERS}`, data, {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      authorization: "Bearer " + data.token,
    },
  })

export const updateBlocker = data =>
  put(`${url.FETCH_BLOCKERS}/${data.id}`, data, {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      authorization: "Bearer " + data.token,
    },
  })

export const deleteBlocker = data =>
  del(`${url.FETCH_BLOCKERS}/${data.id}`, {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      authorization: "Bearer " + data.token,
    },
  })

export const fetchServices = query =>
  put(url.FETCH_SERVICES, query, defaultConfig)

export const fetchDoctors = query =>
  patch(url.FETCH_DOCTORS, query, defaultConfig)

export const bookAppointments = data => {
  return post(url.CREATE_APPOINTMENT, data, {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      authorization: "Bearer " + data.token,
    },
  })
}

export const adminLogin = body => {
  return post(url.ADMIN_LOGIN, body, {
    ...defaultConfig,
    headers: defaultConfig.headers,
  })
}

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  Badge,
  Col,
  Container,
  Row,
  Table,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { fetchAppointments, testToken } from "../../helpers/api_requests"
import logoSm from "../../assets/images/logo-sm.png"
import Loader from "../../components/Common/Loader"
import moment from "moment"
import { capitalize, startCase } from "lodash"
import { formatLocation } from "../../common/functions"
import { CustomColumnFilter } from "../../components/Common/filters"
import SimplePagination from "../../components/Common/SimplePagination"

const initialMessage = {
  error: null,
  warning: null,
  success: null,
}

const Dashboard = () => {
  document.title = " Dashboard | Zoom 36"

  const navigate = useNavigate()

  const [user, setUser] = useState(null)
  const [status, setStatus] = useState("")
  const [username, setUsername] = useState("")
  const [selectedLocation, setSelectedLocation] = useState("all")
  const [message, setMessage] = useState(initialMessage)
  const [toasts, setToasts] = useState([])
  const [appointments, setAppointments] = useState([])
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
  })

  const addToast = newMessage => {
    const newToast = { id: toasts.length, ...newMessage }
    setToasts([...toasts, newToast])

    setTimeout(() => {
      setToasts(prevToasts =>
        prevToasts.filter(toast => toast.id !== newToast.id)
      )
    }, 3000)
  }

  const setDisplayName = useCallback(() => {
    if (user) {
      const currentUsername = `${user?.surname} ${user?.other_names}`
      if (window && currentUsername !== username) {
        const existingAuthUser = JSON.parse(sessionStorage.getItem("authUser"))
        sessionStorage.setItem(
          "authUser",
          JSON.stringify({
            ...existingAuthUser,
            displayName: currentUsername,
          })
        )
        setUsername(currentUsername) // This should only happen if the username changes
      }
    }
  }, [user, username])

  useEffect(() => {
    if (user && user.surname && user.other_names) {
      setDisplayName()
    }
  }, [setDisplayName, user])

  const getTokenFromStorage = () => {
    try {
      if (window) {
        return JSON.parse(sessionStorage.getItem("token") || "{}")
      }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getMessageText = () => {
    if (message.error) {
      return { text: message.error, color: "danger" }
    } else if (message.warning) {
      return { text: message.warning, color: "warning" }
    } else if (message.success) {
      return { text: message.success, color: "success" }
    } else {
      return { text: "", color: "" }
    }
  }

  const doFetchAppointments = useCallback(async () => {
    try {
      setLoading(true)
      const resp = await fetchAppointments({
        location: selectedLocation,
        page: meta.page,
        limit: meta.limit,
        status,
        token: getTokenFromStorage(),
      })
      if (resp.success) {
        setAppointments(resp.data)
        setLoading(false)
        setMeta(prev => ({
          ...prev,
          totalPages: resp.paging.totalPages,
        }))
      } else {
        setLoading(false)
        setMessage({
          error: resp.message || "Failed to fetch appointments",
        })
      }
    } catch (error) {
      setLoading(false)
      setMessage({
        error: "Failed to fetch appointments",
      })
    }
  }, [selectedLocation, status, meta.page, meta.limit])

  const doTestToken = async () => {
    try {
      const resp = await testToken({ token: getTokenFromStorage() })
      if (!resp?.success) {
        sessionStorage.setItem("authUser", null)
        sessionStorage.setItem("token", null)
        navigate("/login")
      } else if (window) {
        const savedUser = JSON.parse(sessionStorage.getItem("authUser") || "{}")
        if (savedUser) {
          setUser(savedUser)
        }
      }
    } catch (error) {
      sessionStorage.setItem("authUser", null)
      sessionStorage.setItem("token", null)
      console.log(error?.response?.data?.message)
      navigate("/login")
    }
  }

  useEffect(() => {
    if (getMessageText().text) {
      setLoading(false)
      addToast({ content: getMessageText().text, type: getMessageText().type })
      setTimeout(() => {
        setMessage(initialMessage)
      }, 10000)
    }
    // eslint-disable-next-line
  }, [message])

  useEffect(() => {
    doFetchAppointments()
    // eslint-disable-next-line
  }, [doFetchAppointments, selectedLocation, status])

  useEffect(() => {
    doTestToken()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user) {
      setSelectedLocation(user.location)
    } else {
      const savedUser = JSON.parse(sessionStorage.getItem("authUser"))
      if (!savedUser) {
        navigate("/login")
      }
    }
  }, [user])

  const getColorByLocation = loc => {
    switch (formatLocation(loc?.toLowerCase())) {
      case "lag":
        return "bg-warning rounded p-1"
      case "fct":
        return "bg-success rounded text-white p-1"
      case "phc":
        return "bg-primary rounded text-white p-1"
      default:
        return "bg-white rounded p-1"
    }
  }

  const locationOptions = useMemo(() => ["Lagos", "Abuja", "Port Harcourt"], [])
  const statusOptions = useMemo(() => ["Pending", "Confirmed"], [])

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Zoom 36" breadcrumbItem="Booked Appointments" />
            <Row>
              <Col lg={12}>
                <div className="wizard clearfix">
                  <div className="content clearfix">
                    <div className="body">
                      <Table className="table-centered datatable dt-responsive nowrap table-card-list react_table">
                        <thead className="table-nowrap">
                          <tr>
                            <th>
                              <CustomColumnFilter
                                options={locationOptions}
                                filterValue={selectedLocation}
                                setFilter={setSelectedLocation}
                              />
                            </th>
                            <th>Name / Address</th>
                            <th>Contact</th>
                            <th>Date / Time</th>
                            <th>
                              <CustomColumnFilter
                                options={statusOptions}
                                filterValue={status}
                                setFilter={setStatus}
                              />
                            </th>
                            <th>Service / Complaints</th>
                            <th style={{ width: "100px" }}>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {appointments?.map((item, index) => {
                            const { user: userDetails } = item
                            return (
                              <Fragment key={index}>
                                <tr>
                                  <td>
                                    <span
                                      className={getColorByLocation(
                                        userDetails.location
                                      )}
                                    >
                                      {userDetails.location?.toUpperCase()}
                                    </span>
                                  </td>
                                  <td>
                                    {startCase(
                                      userDetails.surname +
                                        " " +
                                        userDetails.other_names
                                    )}{" "}
                                    <br />
                                    <span style={{ backgroundColor: "beige" }}>
                                      {capitalize(userDetails.address)}
                                    </span>
                                  </td>
                                  <td>
                                    {userDetails.email?.toLowerCase()} <br />
                                    {userDetails.phone_number}
                                  </td>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {moment(item.appointment_date).format(
                                      "YYYY-MM-DD"
                                    )}{" "}
                                    <br />
                                    {moment(item.appointment_date).format(
                                      "h:mm a"
                                    )}
                                  </td>
                                  <td>
                                    {/* <span
                                      className={
                                        item.isOpen
                                          ? "bg-success rounded text-white p-1"
                                          : "bg-primary rounded text-white p-1"
                                      }
                                    >
                                      {item.isOpen ? "Open" : "Closed"}
                                    </span> */}
                                    {item.isOpen ? (
                                      <Badge className="rounded bg-success">
                                        Open
                                      </Badge>
                                    ) : (
                                      <Badge className="rounded bg-primary">
                                        Closed
                                      </Badge>
                                    )}
                                  </td>
                                  <td>
                                    {item.serviceText} <br />
                                    {item.description}
                                  </td>
                                  <td></td>
                                </tr>
                              </Fragment>
                            )
                          })}
                        </tbody>
                      </Table>
                      <SimplePagination meta={meta} setMeta={setMeta} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div
            className="position-fixed top-0 end-0 mt-5 m-1"
            style={{ zIndex: 1100 }}
          >
            {toasts.map(toast => (
              <Toast
                key={toast.id}
                isOpen={true}
                fade={true}
                className={`bg-${toast.type} rounded mb-2`}
              >
                <ToastHeader
                  toggle={() =>
                    setToasts(toasts.filter(t => t.id !== toast.id))
                  }
                >
                  <img src={logoSm} alt="" className="me-2" height="18" />
                  Zoom 36
                  <small className="text-muted ms-1">just now</small>
                </ToastHeader>
                <ToastBody>{toast.content}</ToastBody>
              </Toast>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Dashboard

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  Badge,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
  Table,
  Toast,
  ToastBody,
  ToastHeader,
  FormGroup,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
  createBlocker,
  deleteBlocker,
  fetchBlockers,
  testToken,
  updateBlocker,
} from "../../helpers/api_requests"
import logoSm from "../../assets/images/logo-sm.png"
import Loader from "../../components/Common/Loader"
import moment from "moment"
import { CustomColumnFilter } from "../../components/Common/filters"
import SimplePagination from "../../components/Common/SimplePagination"
import { daysOfWeek, months } from "../../constants/constants"

const initialMessage = {
  error: null,
  warning: null,
  success: null,
}

const initialData = {
  description: "",
  type: "",
  startDateTime: "",
  endDateTime: "",
  recurringDays: [],
  recurringDates: [],
}

const Config = () => {
  document.title = "Config | Zoom 36"

  const navigate = useNavigate()

  const [data, setData] = useState(initialData)
  const [user, setUser] = useState(null)
  const [username, setUsername] = useState("")
  const [message, setMessage] = useState(initialMessage)
  const [toasts, setToasts] = useState([])
  const [blockers, setBlokers] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState("")
  const [blockerModalOpen, setBlockerModalOpen] = useState(false)
  const [meta, setMeta] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
  })

  const addToast = newMessage => {
    const newToast = { id: toasts.length, ...newMessage }
    setToasts([...toasts, newToast])

    setTimeout(() => {
      setToasts(prevToasts =>
        prevToasts.filter(toast => toast.id !== newToast.id)
      )
    }, 3000)
  }

  const setDisplayName = useCallback(() => {
    if (user) {
      const currentUsername = `${user?.surname} ${user?.other_names}`
      if (window && currentUsername !== username) {
        const existingAuthUser = JSON.parse(sessionStorage.getItem("authUser"))
        sessionStorage.setItem(
          "authUser",
          JSON.stringify({
            ...existingAuthUser,
            displayName: currentUsername,
          })
        )
        setUsername(currentUsername) // This should only happen if the username changes
      }
    }
  }, [user, username])

  useEffect(() => {
    if (user && user.surname && user.other_names) {
      setDisplayName()
    }
  }, [setDisplayName, user])

  const getTokenFromStorage = () => {
    try {
      if (window) {
        return JSON.parse(sessionStorage.getItem("token") || "{}")
      }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getMessageText = () => {
    if (message.error) {
      return { text: message.error, color: "danger" }
    } else if (message.warning) {
      return { text: message.warning, color: "warning" }
    } else if (message.success) {
      return { text: message.success, color: "success" }
    } else {
      return { text: "", color: "" }
    }
  }

  const doFetchBlockers = useCallback(async () => {
    try {
      setLoading(true)
      const resp = await fetchBlockers({
        page: meta.page,
        limit: meta.limit,
        type: selectedType,
        token: getTokenFromStorage(),
      })
      if (resp.success) {
        setBlokers(resp.data)
        setLoading(false)
        setMeta(prev => ({
          ...prev,
          totalPages: resp.paging.totalPages,
        }))
      } else {
        setLoading(false)
        setMessage({
          error: resp.message || "Failed to fetch blockers",
        })
      }
    } catch (error) {
      setLoading(false)
      setMessage({
        error: "Failed to fetch blockers",
      })
    }
  }, [selectedType, meta.page, meta.limit])

  const doTestToken = async () => {
    try {
      const resp = await testToken({ token: getTokenFromStorage() })
      if (!resp?.success) {
        sessionStorage.setItem("authUser", null)
        sessionStorage.setItem("token", null)
        navigate("/login")
      } else if (window) {
        const savedUser = JSON.parse(sessionStorage.getItem("authUser") || "{}")
        if (savedUser) {
          setUser(savedUser)
        }
      }
    } catch (error) {
      sessionStorage.setItem("authUser", null)
      sessionStorage.setItem("token", null)
      console.log(error?.response?.data?.message)
      navigate("/login")
    }
  }

  useEffect(() => {
    if (getMessageText().text) {
      setLoading(false)
      addToast({ content: getMessageText().text, type: getMessageText().type })
      setTimeout(() => {
        setMessage(initialMessage)
      }, 10000)
    }
    // eslint-disable-next-line
  }, [message])

  useEffect(() => {
    doFetchBlockers()
    // eslint-disable-next-line
  }, [doFetchBlockers, selectedType])

  useEffect(() => {
    doTestToken()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!user) {
      const savedUser = JSON.parse(sessionStorage.getItem("authUser"))
      if (!savedUser) {
        navigate("/login")
      }
    }
  }, [user])

  const typeOptions = useMemo(
    () => ["One Time", "Daily", "Weekly", "Monthly", "Yearly"],
    []
  )

  const doCreateBlocker = async () => {
    try {
      if (data && data.type && data.description) {
        let startDateTime = data.startDateTime
        let endDateTime = data.endDateTime

        if (data.type === "Daily") {
          const today = new Date().toISOString().split("T")[0]
          startDateTime = `${today}T${data.startDateTime}`
          endDateTime = `${today}T${data.endDateTime}`
        }
        if (data.type === "Weekly" && (!startDateTime || !endDateTime)) {
          startDateTime = moment().startOf("day").toISOString()
          endDateTime = moment().endOf("day").toISOString()
        }

        if (data.type === "Yearly") {
          const dateString = `${new Date().getFullYear()}-${
            Number(data.selectedMonth) + 1
          }-${data.recurringDates[0] || 1}`

          startDateTime = moment(dateString).startOf("day").toISOString()
          endDateTime = moment(dateString).endOf("day").toISOString()
        }

        const payload = {
          ...data,
          type: data.type,
          startDateTime: new Date(startDateTime).toISOString(),
          endDateTime: new Date(endDateTime).toISOString(),
          token: getTokenFromStorage(),
        }
        console.log({ payload })
        const resp = data.id
          ? await updateBlocker(payload)
          : await createBlocker(payload)

        if (resp.success) {
          setMessage({ success: "Success!" })
          setData(initialData)
          setBlockerModalOpen(false)
          doFetchBlockers()
        } else {
          setMessage({ success: resp.message || "Created successfully!" })
        }
      }
    } catch (error) {
      console.log({ error })
      setMessage({ error: error?.message || "Error creating blocker" })
    }
  }

  const changeData = item => {
    if (data) {
      setData(prev => ({ ...prev, ...item }))
    } else {
      setData(item)
    }
  }

  const generateDatesArray = () => {
    const dates = []
    for (let i = 1; i <= 31; i++) {
      dates.push(i)
    }
    return dates
  }

  const handleEdit = item => {
    // Set the edit data and open modal
    setData({
      id: item.id,
      description: item.description,
      type: item.type,
      startDateTime: item.startDateTime,
      endDateTime: item.endDateTime,
      recurringDays: item.recurringDays,
      recurringDates: item.recurringDates,
      selectedMonth:
        item.type === "yearly" ? moment(item.startDateTime).month() : null,
    })
    setBlockerModalOpen(true)
  }

  const handleDelete = async id => {
    if (window.confirm("Are you sure you want to delete this blocker?")) {
      try {
        const resp = await deleteBlocker({ id, token: getTokenFromStorage() })
        if (resp.success) {
          setMessage({ success: resp.message })
          doFetchBlockers()
        } else {
          setMessage({ error: resp.message })
        }
      } catch (error) {
        setMessage({ error: "Failed to delete blocker" })
        console.error("Failed to delete blocker:", error)
      }
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Zoom 36"
              breadcrumbItem="Appointment Blockers"
              buttons={[
                {
                  title: "Add Blocker",
                  action: () => {
                    setBlockerModalOpen(true)
                  },
                  color: "primary",
                },
              ]}
            />
            <Row>
              <Col lg={12}>
                <div className="wizard clearfix">
                  <div className="content clearfix">
                    <div className="body">
                      <Table className="table-centered datatable dt-responsive nowrap table-card-list react_table">
                        <thead className="table-nowrap">
                          <tr>
                            <th>Description</th>
                            <th>
                              <CustomColumnFilter
                                options={typeOptions}
                                filterValue={selectedType}
                                setFilter={setSelectedType}
                              />
                            </th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Days</th>
                            <th>Dates</th>
                            <th style={{ width: "100px" }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {blockers?.map((item, index) => {
                            const recurringDays = item.recurringDays
                            const recurringDates = item.recurringDates
                            // Helper function to get day names
                            const getDayNames = days => {
                              const dayNames = [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednessday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                              ]
                              return `Every ${days
                                .map(day => dayNames[day])
                                .join(", ")}`
                            }

                            // Helper function to format dates list
                            const formatDates = dates => {
                              return dates.sort((a, b) => a - b).join(", ")
                            }

                            return (
                              <Fragment key={index}>
                                <tr>
                                  <td>{item.description}</td>
                                  <td>
                                    <Badge className="rounded bg-success p-2">
                                      {item.type}
                                    </Badge>
                                  </td>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {item.type !== "Daily" &&
                                      moment(item.startDateTime).format(
                                        "YYYY-MM-DD"
                                      )}
                                    {item.type !== "Daily" && <br />}
                                    {moment(item.startDateTime).format(
                                      "h:mm a"
                                    )}
                                  </td>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {item.type !== "Daily" &&
                                      moment(item.endDateTime).format(
                                        "YYYY-MM-DD"
                                      )}
                                    {item.type !== "Daily" && <br />}
                                    {moment(item.endDateTime).format("h:mm a")}
                                  </td>
                                  <td>
                                    {item.type === "Weekly" &&
                                      getDayNames(recurringDays)}
                                    {item.type === "Daily" && "Every day"}
                                  </td>
                                  <td>
                                    {item.type === "Monthly" &&
                                      formatDates(recurringDates)}
                                    {item.type === "Yearly" &&
                                      `${moment(item.startDateTime).format(
                                        "MMMM"
                                      )} ${formatDates(recurringDates)}`}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <Button
                                        color="info"
                                        className="btn-sm"
                                        onClick={() => handleEdit(item)}
                                      >
                                        <i className="fas fa-edit"></i>
                                      </Button>
                                      <Button
                                        color="danger"
                                        className="btn-sm"
                                        onClick={() => handleDelete(item.id)}
                                      >
                                        <i className="fas fa-trash-alt"></i>
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              </Fragment>
                            )
                          })}
                        </tbody>
                      </Table>
                      <SimplePagination meta={meta} setMeta={setMeta} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Modal isOpen={blockerModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">Create Blocker</h5>
              <button
                type="button"
                onClick={() => {
                  setBlockerModalOpen(false)
                  setData(initialData)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Container fluid>
                <Row className="mb-3">
                  <Col>
                    <FormGroup>
                      <Label for="description">Description</Label>
                      <Input
                        type="text"
                        id="description"
                        value={data.description}
                        onChange={e =>
                          changeData({ description: e.target.value })
                        }
                        placeholder="Enter blocker description"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <FormGroup>
                      <Label for="type">Type</Label>
                      <Input
                        type="select"
                        id="type"
                        value={data.type}
                        onChange={e => {
                          changeData({
                            type: e.target.value,
                            recurringDays: [],
                            recurringDates: [],
                          })
                        }}
                      >
                        <option value="">Choose...</option>
                        {typeOptions.map((t, i) => (
                          <option key={i} value={t}>
                            {t}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                {["Daily", "Weekly", "Monthly"].includes(data.type) ? (
                  // Time-only selection for Daily type
                  <Row className="mb-3">
                    <Col md={6}>
                      <FormGroup>
                        <Label for="startTime">Start Time</Label>
                        <Input
                          type="time"
                          id="startTime"
                          value={data.startDateTime}
                          onChange={e =>
                            changeData({ startDateTime: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="endTime">End Time</Label>
                        <Input
                          type="time"
                          id="endTime"
                          value={data.endDateTime}
                          onChange={e =>
                            changeData({ endDateTime: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : data.type === "One Time" ? (
                  <Row className="mb-3">
                    <Col md={6}>
                      <FormGroup>
                        <Label for="startDateTime">Start Date & Time</Label>
                        <Input
                          type="datetime-local"
                          id="startDateTime"
                          value={data.startDateTime}
                          onChange={e =>
                            changeData({ startDateTime: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="endDateTime">End Date & Time</Label>
                        <Input
                          type="datetime-local"
                          id="endDateTime"
                          value={data.endDateTime}
                          onChange={e =>
                            changeData({ endDateTime: e.target.value })
                          }
                          min={data.startDateTime}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : (
                  <Row></Row>
                )}

                {data.type === "Weekly" && (
                  <Row className="mb-3">
                    <Col>
                      <Label>Select Days</Label>
                      <div className="d-flex flex-wrap gap-3">
                        {daysOfWeek.map(day => (
                          <FormGroup check key={day.value}>
                            {/* <Input
                              className="text-primary"
                              type="checkbox"
                              id={`day-${day.value}`}
                              checked={
                                data.recurringDays?.includes(day.value) || false
                              }
                              onChange={e => {
                                console.log({ checked: e.target.checked })
                                console.log({ dayValue: day.value })
                                const newDays = e.target.checked
                                  ? [...data.recurringDays, day.value]
                                  : data.recurringDays.filter(
                                      d => d !== day.value
                                    )
                                changeData({ recurringDays: newDays })
                              }}
                            /> */}
                            <Input
                              type="checkbox"
                              className="form-check-input bg-primary border-primary"
                              id={`day-${day.value}`}
                              value={data.recurringDays?.includes(day.value)}
                              onClick={() => {
                                const alreadyChecked =
                                  data.recurringDays?.includes(day.value)
                                const newDays = alreadyChecked
                                  ? data.recurringDays.filter(
                                      d => d !== day.value
                                    )
                                  : [...data.recurringDays, day.value]

                                changeData({ recurringDays: newDays })
                              }}
                            />
                            <Label check for={`day-${day.value}`}>
                              {day.label}
                            </Label>
                          </FormGroup>
                        ))}
                      </div>
                    </Col>
                  </Row>
                )}

                {data.type === "Monthly" && (
                  <Row className="mb-3">
                    <Col>
                      <Label>Select Dates</Label>
                      <div className="d-flex flex-wrap gap-2">
                        {generateDatesArray().map(date => (
                          <FormGroup check key={date} className="me-2">
                            <Input
                              type="checkbox"
                              id={`date-${date}`}
                              checked={data.recurringDates.includes(date)}
                              onChange={e => {
                                const newDates = e.target.checked
                                  ? [...data.recurringDates, date]
                                  : data.recurringDates.filter(d => d !== date)
                                changeData({ recurringDates: newDates })
                              }}
                            />
                            <Label check for={`date-${date}`}>
                              {date}
                            </Label>
                          </FormGroup>
                        ))}
                      </div>
                    </Col>
                  </Row>
                )}

                {data.type === "Yearly" && (
                  <Row className="mb-3">
                    <Col>
                      <Label>Select Month and Date</Label>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="month">Month</Label>
                            <Input
                              type="select"
                              id="month"
                              value={data.selectedMonth || ""}
                              onChange={e => {
                                changeData({
                                  selectedMonth: e.target.value,
                                  recurringDates: [], // Reset dates when month changes
                                })
                              }}
                            >
                              <option value="">Select Month</option>
                              {months.map(month => (
                                <option key={month.value} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="date">Date</Label>
                            <Input
                              type="select"
                              id="date"
                              value={data.recurringDates[0] || ""}
                              onChange={e => {
                                changeData({
                                  recurringDates: [parseInt(e.target.value)],
                                })
                              }}
                              disabled={!data.selectedMonth}
                            >
                              <option value="">Select Date</option>
                              {generateDatesArray()
                                .slice(
                                  0,
                                  // Limit dates based on selected month
                                  data.selectedMonth === 1
                                    ? 29 // February
                                    : [3, 5, 8, 10].includes(
                                        parseInt(data.selectedMonth)
                                      )
                                    ? 30
                                    : 31 // Other months
                                )
                                .map(date => (
                                  <option key={date} value={date}>
                                    {date}
                                  </option>
                                ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Container>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setBlockerModalOpen(false)
                    setData(initialData)
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    doCreateBlocker()
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </Modal>

          <div
            className="position-fixed top-0 end-0 mt-5 m-1"
            style={{ zIndex: 1100 }}
          >
            {toasts.map(toast => (
              <Toast
                key={toast.id}
                isOpen={true}
                fade={true}
                className={`bg-${toast.type} rounded mb-2`}
              >
                <ToastHeader
                  toggle={() =>
                    setToasts(toasts.filter(t => t.id !== toast.id))
                  }
                >
                  <img src={logoSm} alt="" className="me-2" height="18" />
                  Zoom 36
                  <small className="text-muted ms-1">just now</small>
                </ToastHeader>
                <ToastBody>{toast.content}</ToastBody>
              </Toast>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Config

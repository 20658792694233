import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Button } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0">{props.breadcrumbItem}</h4>
          {props.buttons?.length ? (
            props.buttons?.map((b, i) => {
              return (
                <Button
                  key={i}
                  type="button"
                  color={b.color || "primary"}
                  onClick={() => {
                    if (b.action) {
                      b.action()
                    }
                  }}
                  title={b.title}
                >
                  {b.title}
                </Button>
              )
            })
          ) : (
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link to="#">{props.title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">{props.breadcrumbItem}</Link>
                </BreadcrumbItem>
              </ol>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  buttons: PropTypes.any, // [{ title: string, action: () => void, color?: string }]
}

export default Breadcrumb

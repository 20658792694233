import React from "react"
import { Navigate } from "react-router-dom"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Appointments from "../pages/Appointments/index"
import Dashboard from "../pages/Dashboard/index"
import Config from "../pages/Config/index"

const userRoutes = [
  { path: "/book-appointment", component: <Appointments /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/config", component: <Config /> },
  // { path: "/pages-starter", component: <PagesStarter /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/book-appointment" />,
  },
  { path: "*", component: <Navigate to="/" /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
  // { path: "/pages-404", component: <Pages404 /> },
  // { path: "/pages-500", component: <Pages500 /> },
]

export { userRoutes, authRoutes }

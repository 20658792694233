import React from "react"

const SimplePagination = ({ meta, setMeta }) => {
  return meta ? (
    <div className="flex justify-center space-x-2 mt-4">
      <button
        disabled={meta.page === 1}
        onClick={() =>
          setMeta(prev => ({
            ...prev,
            page: Math.max(prev.page - 1, 1),
          }))
        }
        className="px-3 py-1 border rounded-lg"
      >
        Prev
      </button>
      <span className="p-2">
        Page {meta.page} of {meta.totalPages}
      </span>
      <button
        disabled={meta.page === meta.totalPages}
        onClick={() =>
          setMeta(prev => ({
            ...prev,
            page: Math.min(prev.page + 1, meta.totalPages),
          }))
        }
        className="px-3 py-1 border rounded-lg"
      >
        Next
      </button>
    </div>
  ) : (
    <div></div>
  )
}

export default SimplePagination

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap"
import { Link, router, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import * as Yup from "yup"
import { useFormik } from "formik"
import logolight from "../../assets/images/logo-light.png"
import { adminLogin } from "../../helpers/api_requests"
import logoSm from "../../assets/images/logo-sm.png"

const initialMessage = {
  error: null,
  warning: null,
  success: null,
}

const Login = () => {
  document.title = " Login | Zoom 36"
  const navigate = useNavigate()

  const [toasts, setToasts] = useState([])
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(initialMessage)

  const addToast = newMessage => {
    const newToast = { id: toasts.length, ...newMessage }
    setToasts([...toasts, newToast])

    setTimeout(() => {
      setToasts(prevToasts =>
        prevToasts.filter(toast => toast.id !== newToast.id)
      )
    }, 3000)
  }

  const getMessageText = () => {
    if (message.error) {
      return { text: message.error, color: "danger" }
    } else if (message.warning) {
      return { text: message.warning, color: "warning" }
    } else if (message.success) {
      return { text: message.success, color: "success" }
    } else {
      return { text: "", color: "" }
    }
  }

  const resetMessage = () => {
    setMessage(initialMessage)
  }

  useEffect(() => {
    if (getMessageText().text) {
      setLoading(false)
      addToast({ content: getMessageText().text, type: getMessageText().type })
      setTimeout(() => {
        setMessage(initialMessage)
      }, 10000)
    }
    // eslint-disable-next-line
  }, [message])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      try {
        resetMessage()
        setLoading(true)
        const resp = await adminLogin({
          username: values.username?.trim()?.toLowerCase(),
          password: values.password,
        })
        if (resp.success) {
          setMessage({ success: resp?.message || "Success!" })
          sessionStorage.setItem("authUser", JSON.stringify(resp.data.user))
          sessionStorage.setItem("token", JSON.stringify(resp.data.token))
          navigate("/dashboard")
          setLoading(false)
        } else {
          setMessage({ error: resp?.message || "Login error!" })
          setLoading(false)
          sessionStorage.setItem("authUser", null)
          sessionStorage.setItem("token", null)
        }
      } catch (error) {
        setMessage({ error: error?.response?.data?.message || "Login error!" })
        setLoading(false)
        sessionStorage.setItem("authUser", null)
        sessionStorage.setItem("token", null)
      }
    },
  })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  useEffect(() => {
    document.body.className = "authentication-bg"
    return function cleanup() {
      document.body.className = ""
    }
  })

  useEffect(() => {
    sessionStorage.clear()
  }, [])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logolight}
                    alt=""
                    width={120}
                    className="logo logo-dark"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="22"
                    className="logo logo-light"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back!</h5>
                    <p className="text-muted">
                      Sign in to continue to the portal.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter username"
                          type="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Logging in..." : "Sign In"}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center text-white">
                <p>
                  © {new Date().getFullYear()} Zoom 36. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Techtink
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          className="position-fixed top-0 end-0 mt-5 m-1"
          style={{ zIndex: 1100 }}
        >
          {toasts.map(toast => (
            <Toast
              key={toast.id}
              isOpen={true}
              fade={true}
              className={`bg-${toast.type} rounded mb-2`}
            >
              <ToastHeader
                toggle={() => setToasts(toasts.filter(t => t.id !== toast.id))}
              >
                <img src={logoSm} alt="" className="me-2" height="18" />
                Zoom 36
                <small className="text-muted ms-1">just now</small>
              </ToastHeader>
              <ToastBody>{toast.content}</ToastBody>
            </Toast>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)
